export enum Translation {
	SETTINGS,
	MUSIC_CREDIT,
	CONTACT,
	CONTACT_ME,
	HELP,
	HELLO,
	WELCOME_TO_MY_WEBSITE,
	I_AM,
	FULLSTACK_DEVELOPER,
	SOCIAL_NETWORKS,
	EMAIL,
	SEND,
	FIRSTNAME_LASTNAME,
	SUBJECT,
	MESSAGE,
	ABOUT_ME,
	HI_THERE,
	ABOUT_TEXT,
	ME,
	SKILLS,
	SEARCH,
	SYSTEMS,
	OS,
	DISTRIBUTIONS_VERSIONS,
	TOOLS_SOFTWARE ,
	MODELING_PROTOTYPING,
	TEXT_EDITOR_IDE,
	VERSIONNING,
	BUNDLER,
	DEPENDENCY_MANAGER,
	SGBD,
	MANAGEMENT,
	LANGAGES,
	MODELING,
	FRONT,
	BACK,
	SYSTEM,
	DATABASE,
	FRAMEWORKS_LIBRARIES,
	CSS,
	TESTING,
	VIRTUALIZATION,
	DEVOPS,
	SECURITY,
	WEB_SERVER,
	PROJECTS,
	EDUCATION,
	EXPERIENCE,
	READ_MORE,
	EPITECH_DESCRIPTION,
	UNIVERSITY,
	CFA_SCIENCES,
	LYCEE,
	TODAY,
	SUMMER_JOB,
	APPRENTICE,
	MONTHS,
	YEARS,
	TESTER,
	INTERN,
	CASHIER_SHELF_WORKER,
	DCBRAIN_INTERN_WORK,
	DCBRAIN_FREELANCE_WORK,
	DCBRAIN_APPRENTICE_1,
	DCBRAIN_APPRENTICE_2,
	DCBRAIN_APPRENTICE_3,
	SEZAME_FREELANCE_WORK,
	PERSONALS,
	PROFESSIONALS,
	ACADEMICS,
	IN_DEV,
	PORTFOLIO_V1,
	PORTFOLIO_V2,
	GAMEBOY,
	BRICKSWORLD,
	TRANSLATORDC,
	BRAIN,
	MYSPORT,
	ATHLETEC,
	DCTESTS,
	CANVAS_GRID,
	E2E_TEST_AUTOMATION,
	ECOMMERCE,
	REDDITECH,
	TEMP_CHAT,
	YOU_SHALL_NOT_PASS,
	EPIVIBES,
	JOB_BOARD,
	DATA_ANALYZER,
	KILLEDDB,
	BLINDTEST,
	SLACKLIKE,
	IBUYSU,
	CITYCAR,
	MULTIPLICATION,
	SPARKLINE,
	GUESSWHAT,
	VIRTUAL_LANDSCAPE,
	GEOWORLD,
	AEROCLUB_FROTEY_LES_LURE,
	BOUCHERIE_MEME,
}
